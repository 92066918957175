import React, { useEffect, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { Typography, Slide, Grid, IconButton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowBackIos as ArrowBackwardIcon } from "@mui/icons-material";
import { formatMessageTime, formatDayHeader } from "@/utils/dateUtils";
import dayjs from "dayjs";
import { SearchClients } from "@/domain/client.interface";
import { useGetSettings } from "@/hooks/useSetting";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import { useTranslation } from "react-i18next";
import { MessageModelExtended } from "@/domain/chat.interface";
import SkeletonLoader from "@/components/SkeletonLoader";
import DOMPurify from "dompurify";

interface ChatWindowProps {
  selectedContactData: SearchClients | undefined;
  contactMessages: MessageModelExtended[];
  onSend: (message: string) => void;
  onGoBack: () => void;
  visible?: boolean;
  isMobile?: boolean;
  isMessagesLoading: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  selectedContactData,
  contactMessages = [],
  onSend,
  onGoBack,
  visible = true,
  isMobile = false,
  isMessagesLoading = false,
}) => {
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const { data: settings } = useGetSettings();
  const { showSnackBar } = useFeedBack();
  const { t } = useTranslation("clients");

  const handleSend = () => {
    if (message.trim()) {
      const sanitizedMessage = DOMPurify.sanitize(message, {
        ALLOWED_TAGS: [],
        ALLOWED_ATTR: [],
      });
      const removeNbsp = sanitizedMessage
        .replace(/<[^>]*>/g, "")
        .replace(/&nbsp;/g, " ")
        .trim();
      onSend(removeNbsp);
      setMessage("");
    }
  };

  const handleOnChange = (value: string, textContent: string) => {
    if (
      settings?.chatMaxMessageLength &&
      textContent.trim().length > (settings.chatMaxMessageLength as number)
    ) {
      showSnackBar(t("chat.message_max_length"), "error");
    } else if (settings) {
      setMessage(value);
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const plainText = event.clipboardData.getData("text/plain");
    setMessage((prev) => prev + plainText);
  };

  useEffect(() => {
    setMessage("");
  }, [selectedContactData]);

  return (
    <Slide
      direction="right"
      in={!!selectedContactData && visible}
      mountOnEnter
      unmountOnExit
      timeout={250}
    >
      <Grid item xs={12} md={7}>
        {selectedContactData ? (
          <MainContainer style={{ border: "none", background: "transparent" }}>
            <ChatContainer
              style={{
                background: "transparent",
                height: "calc(100vh - 122px)",
              }}
            >
              <ConversationHeader
                style={{
                  backgroundColor: theme.palette.surface.light2,
                  border: "none",
                  paddingTop: 0,
                  paddingLeft: 0,
                }}
              >
                {isMobile ? (
                  <ConversationHeader.Back>
                    <IconButton onClick={onGoBack} sx={{ ml: 1 }}>
                      <ArrowBackwardIcon />
                    </IconButton>
                  </ConversationHeader.Back>
                ) : (
                  <Avatar
                    onClick={onGoBack}
                    style={{
                      background: theme.palette.surface.main,
                      color: theme.palette.textMain.dark2,
                      width: "40px",
                      height: "40px",
                      lineHeight: "40px",
                      textAlign: "center",
                      cursor: "pointer",
                      fontWeight: theme.typography.fontWeightMedium,
                    }}
                  >
                    {selectedContactData.participant.firstName
                      .charAt(0)
                      ?.toUpperCase()}
                    {selectedContactData.participant.lastName
                      .charAt(0)
                      ?.toUpperCase()}
                  </Avatar>
                )}
                <ConversationHeader.Content
                  style={{
                    backgroundColor: theme.palette.surface.light2,
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    {selectedContactData.participant.firstName}{" "}
                    {selectedContactData.participant.lastName}
                  </Typography>
                </ConversationHeader.Content>
              </ConversationHeader>
              {isMessagesLoading ? (
                <MessageList>
                  <MessageList.Content>
                    <SkeletonLoader
                      isLoading={isMessagesLoading}
                      skeletonCount={5}
                      skeletonHeight={64}
                      skeletonType="rectangular"
                    >
                      <></>
                    </SkeletonLoader>
                  </MessageList.Content>
                </MessageList>
              ) : (
                <MessageList
                  style={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    WebkitOverflowScrolling: "touch",
                  }}
                >
                  {contactMessages?.map((msg, index) => {
                    const currentDay = msg.sentTime
                      ? dayjs(Number(msg.sentTime)).startOf("day")
                      : null;
                    const previousDay =
                      index > 0 && contactMessages[index - 1]?.sentTime
                        ? dayjs(
                            Number(contactMessages[index - 1].sentTime)
                          ).startOf("day")
                        : null;

                    const showDayHeader =
                      currentDay &&
                      (!previousDay || !currentDay.isSame(previousDay));

                    return (
                      <>
                        {showDayHeader ? (
                          <MessageSeparator
                            key={`separator-${msg.sentTime}-${index}`}
                            className="no-line-separator"
                          >
                            <Typography
                              variant="caption"
                              style={{
                                display: "block",
                                fontWeight: theme.typography.fontWeightBold,
                                textAlign: "center",
                                margin: "8px 0",
                                color: theme.palette.textMain.light2,
                              }}
                            >
                              {formatDayHeader(Number(msg.sentTime))}
                            </Typography>
                          </MessageSeparator>
                        ) : (
                          <></>
                        )}
                        <Message
                          key={`message-${msg.sentTime}-${index}-${msg.direction}`}
                          className="custom-message"
                          model={{
                            sender: msg.sender,
                            direction: msg.direction,
                            position: msg.position,
                            sentTime: msg.sentTime,
                          }}
                          avatarPosition="tl"
                        >
                          {msg.direction === "incoming" && (
                            <Avatar
                              style={{
                                backgroundColor: theme.palette.surface.main,
                                width: 24,
                                height: 24,
                                minHeight: 24,
                                minWidth: 24,
                                lineHeight: "24px",
                                textAlign: "center",
                                fontSize: theme.typography.caption.fontSize,
                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {selectedContactData?.participant?.firstName
                                ?.charAt(0)
                                ?.toUpperCase() || ""}
                              {selectedContactData?.participant?.lastName
                                ?.charAt(0)
                                ?.toUpperCase() || ""}
                            </Avatar>
                          )}
                          <Message.CustomContent
                            key={`custom-content-${msg.sentTime}-${index}`}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                width: "100%",
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{
                                  flex: 1,
                                  alignSelf: "flex-start",
                                }}
                              >
                                <Message.HtmlContent
                                  key={`html-content-${msg.sentTime}-${index}-${msg.direction}`}
                                  html={msg?.message || "no content available"}
                                />
                              </Typography>
                              <Typography
                                fontWeight={theme.typography.fontWeightBold}
                                variant="caption"
                                style={{
                                  alignSelf: "flex-end",
                                  marginLeft: "8px",
                                }}
                              >
                                {formatMessageTime(Number(msg.sentTime))}
                              </Typography>
                            </Box>
                          </Message.CustomContent>
                        </Message>
                      </>
                    );
                  })}
                </MessageList>
              )}
              <MessageInput
                disabled={
                  selectedContactData?.lastEnrollment.status === "COMPLETED"
                }
                className={`custom-message-input ${selectedContactData?.lastEnrollment.status === "COMPLETED" ? "completed" : ""} ${
                  !message.trim() || message.trim() === "<br>"
                    ? "custom-message-input-disabled"
                    : ""
                }`}
                style={{
                  height: "auto",
                  maxHeight: "150px",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  padding: 8,
                  borderTop: "none",
                  backgroundColor: theme.palette.surface.main,
                  fontSize: theme.typography.caption.fontSize,
                  color: theme.palette.icon.dark2,
                  overflowY: "auto",
                }}
                attachButton={false}
                placeholder="Type something..."
                onSend={handleSend}
                value={message}
                onChange={handleOnChange}
                onPaste={handlePaste}
                sendDisabled={
                  selectedContactData?.lastEnrollment.status === "COMPLETED"
                }
              />
            </ChatContainer>
          </MainContainer>
        ) : (
          <></>
        )}
      </Grid>
    </Slide>
  );
};

export default ChatWindow;
