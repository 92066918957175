import React, { useState, useEffect, useMemo } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  ChimeSDKMessagingClient,
  SendChannelMessageCommand,
} from "@aws-sdk/client-chime-sdk-messaging";
import ContactList from "@/ui/content/chat/ContactList";
import ChatWindow from "@/ui/content/chat/ChatWindow";
import { Grid, useMediaQuery } from "@mui/material";
import theme from "@/utils/theme";
import { ChimeService } from "@/services/chime.service";
import { useUserStore } from "@/hooks/useUserStore";
import { useClients } from "@/hooks/useClients";
import { ParticipantStatus } from "@/domain/participant.enum";
import { SearchClients } from "@/domain/client.interface";
import { useAuditLogFirstRender } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { MessageModelExtended } from "@/domain/chat.interface";
import { useChatMessages } from "@/hooks/useChatMessages";
import { useGetSettings } from "@/hooks/useSetting";
import { usePageStore } from "@/hooks/usePageStore";

const ChatPage: React.FC = () => {
  const { data: settings } = useGetSettings();
  const [contacts, setContacts] = useState<SearchClients[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<SearchClients[]>([]);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [messagingClient, setMessagingClient] =
    useState<ChimeSDKMessagingClient | null>(null);
  const { setPageTitle } = usePageStore();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { userInfo } = useUserStore();

  const [search, setSearch] = useState("");

  useEffect(() => {
    setPageTitle("Messages");
  }, []);

  const { clients, isLoadingClients } = useClients({
    pageNumber: 0,
    pageSize: 100,
    search: "",
    sortField: "participant.firstName",
    sortOrder: 1,
    userId: userInfo?._id as string,
    status: [ParticipantStatus.ACTIVE, ParticipantStatus.COMPLETED],
    programId: userInfo?.programId as string,
  });

  const {
    contactMessages,
    isLoading: isMessagesLoading,
    setContactMessages,
  } = useChatMessages({
    contacts,
    userInfo: {
      _id: userInfo?._id || "",
      chimeInstanceUserArn: userInfo?.chimeInstanceUserArn,
    },
    settings,
  });

  const selectedContactData = useMemo(
    () =>
      contacts.find(
        (c) => c.participant.chimeInstanceUserArn === selectedContact
      ),
    [contacts, selectedContact]
  );

  useEffect(() => {
    if (!settings?.chatModule) return;
    const initializeClient = async () => {
      try {
        if (!userInfo?._id || messagingClient) return;
        const client = await ChimeService.createMessagingClient(userInfo._id);
        setMessagingClient(client);
      } catch (error) {
        console.error(
          "Error initializing Chime messaging client on chat page:",
          error
        );
      }
    };

    initializeClient();
  }, [userInfo?._id, messagingClient, settings]);

  useEffect(() => {
    if (clients?.participants) {
      setContacts(clients.participants);
      setFilteredContacts(clients.participants);
    }
  }, [clients]);

  useEffect(() => {
    const lowerCasedSearch = search.toLowerCase();
    const filtered = contacts.filter(
      (contact) =>
        contact.participant.firstName
          .toLowerCase()
          .includes(lowerCasedSearch) ||
        contact.participant.lastName.toLowerCase().includes(lowerCasedSearch)
    );
    setFilteredContacts(filtered);
  }, [search, contacts]);

  const handleSend = async (message: string) => {
    if (!selectedContact || !messagingClient) return;

    const newMessage: MessageModelExtended = {
      message,
      sentTime: Date.now().toString(),
      sender: "User",
      direction: "outgoing",
      position: "single",
      messageId: `${Date.now()}`,
    };

    setContactMessages((prevMessages) => ({
      ...prevMessages,
      [selectedContact]: [...(prevMessages[selectedContact] || []), newMessage],
    }));

    try {
      await messagingClient.send(
        new SendChannelMessageCommand({
          ChannelArn: contacts
            .find((c) => c.participant.chimeInstanceUserArn === selectedContact)
            ?.lastEnrollment?.chatContacts?.find(
              (o) => o.officerId === userInfo?._id
            )?.channelArn as string,
          Content: message,
          Persistence: "PERSISTENT",
          Type: "STANDARD",
          ChimeBearer: userInfo?.chimeInstanceUserArn,
          ClientRequestToken: `${Date.now()}`,
        })
      );

      console.log("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useAuditLogFirstRender({
    appType: "WEB_BACK_OFFICE",
    module: MODULE_NAME.CHAT,
    option: OPTIONS.CHAT_CONTACTS,
    actionCode: ACTION_CODE.CHAT_CONTACTS_VIEW,
    action: "When entering",
    detail: "Viewed the chat contacts list",
    transactionDate: new Date(),
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    createdBy: userInfo?._id as string,
  });

  return (
    <Grid container spacing={2.5}>
      <ContactList
        isLoading={isLoadingClients || isMessagesLoading}
        contacts={filteredContacts}
        visible={selectedContact === null}
        isMobile={isMobile}
        selectedContactId={selectedContact}
        onSelectContact={setSelectedContact}
        contactMessages={contactMessages}
        search={search}
        onSearchChange={setSearch}
      />
      <ChatWindow
        isMobile={isMobile}
        selectedContactData={selectedContactData}
        contactMessages={contactMessages[selectedContact || ""]}
        onSend={handleSend}
        onGoBack={() => setSelectedContact(null)}
        isMessagesLoading={isMessagesLoading}
      />
    </Grid>
  );
};

export default ChatPage;
