import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FC, ReactNode, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import MainMenu from "../../ui/menus/main";
import { useAppTheme, ExtendedTypographyOptions } from "../../utils/theme";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useUserStore } from "../../hooks/useUserStore";
import { usePageStore } from "@/hooks/usePageStore";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useNavigate } from "react-router-dom";
import { useGetSettings } from "@/hooks/useSetting";
import Badge from "@mui/material/Badge";
import { useNotificationStore } from "@/hooks/useUserNotifications";
import { useMessagingNotifications } from "@/hooks/useMessagingNotifications";
import { ParticipantStatus } from "@/domain/participant.enum";
import { useClients } from "@/hooks/useClients";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  minHeight: "calc(100vh - 82px)",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2.5),
  marginTop: "82px",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    padding: theme.spacing(1.5),
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  height: "82px",
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
  },
}));

type LayoutProps = {
  children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
  const theme = useAppTheme();
  const { user } = useAuth0();
  const { isLoading, userInfo, roles } = useUserStore();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isMobile);
  const { pageTitle } = usePageStore();
  const navigate = useNavigate();
  const { data: settings } = useGetSettings();

  const { clients } = useClients({
    pageNumber: 0,
    pageSize: 100,
    search: "",
    sortField: "participant.firstName",
    sortOrder: 1,
    userId: userInfo?._id as string,
    status: [ParticipantStatus.ACTIVE, ParticipantStatus.COMPLETED],
    programId: userInfo?.programId as string,
  });

  useMessagingNotifications(
    userInfo?.chimeInstanceUserArn || "",
    userInfo?._id || "",
    clients?.participants || [],
    settings,
    user?.email as string
  );

  const { notifications } = useNotificationStore();
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const tempShowNotificationButton = false;

  useEffect(() => {
    if (isMobile && open) {
      setOpen(false);
    } else if (!isMobile && !open) {
      setOpen(true);
    }
  }, [isMobile]);
  return (
    <Box
      sx={{ display: "flex", backgroundColor: theme.palette.surface.light2 }}
      data-testid="layout"
    >
      <CssBaseline />
      <AppBar position="fixed" open={open} className="flex">
        <Toolbar className="flex-1 ">
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            data-testid="drawer-button"
            onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
          >
            <MenuIcon />
          </IconButton>
          <div className="flex flex-1 rounded-lg px-1 py-3 max-w-xl items-center justify-between h-12">
            <Typography
              color={theme.palette.textMain.dark2}
              fontWeight={700}
              fontSize={
                (theme.typography as ExtendedTypographyOptions).titleSmall
                  ?.fontSize
              }
            >
              {pageTitle}
            </Typography>
          </div>
        </Toolbar>
        {tempShowNotificationButton ? (
          <Box
            sx={{
              marginLeft: "auto",
              alignItems: "center",
              display: "flex",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <Tooltip title="Notifications">
              <IconButton size="large">
                <NotificationsNoneOutlinedIcon className="text-icon-primary" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}
        {settings?.chatModule ? (
          <Box
            sx={{
              marginLeft: "auto",
              alignItems: "center",
              display: "flex",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <Tooltip title="Messages">
              <Badge
                sx={{
                  "& .MuiBadge-dot": {
                    top: 15,
                    right: 15,
                  },
                }}
                color="error"
                variant="dot"
                invisible={unreadCount < 1}
              >
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  size="large"
                  onClick={() => {
                    navigate("/messages");
                  }}
                >
                  <ChatBubbleOutlineIcon
                    className="text-icon-primary"
                    sx={{
                      color: theme.palette.icon.dark2,
                    }}
                  />
                </IconButton>
              </Badge>
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            flexDirection: "column",
            backgroundColor: theme.palette.menu.dark,
            height: "100vh",
            padding: "1.25rem 0.5rem",
            gap: "1rem",
          },
        }}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        data-testid="drawer"
      >
        <div
          style={{ width: "100%", textAlign: "center", marginBottom: "1rem" }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/parca-menu-logo.svg`}
            alt="PARCA"
            className="w-48 h-24 mx-auto"
            style={{ display: "block", margin: "0 auto" }}
          />
        </div>
        <SkeletonLoader
          isLoading={isLoading}
          skeletonCount={1}
          skeletonHeight={120}
        >
          <Box
            color={theme.palette.textMain.light}
            sx={{ textAlign: "center" }}
          >
            <Typography fontWeight={700} variant="h6" component="div">
              PARCA
            </Typography>
            <Typography variant="body2">{userInfo?.account?.name}</Typography>
            <Typography variant="body2">
              {(roles.length > 0 && roles[0].name) || ""}
            </Typography>
            {user && (
              <>
                <Typography textTransform={"uppercase"} variant="body2">
                  {userInfo?.firstName} {userInfo?.lastName}
                </Typography>
                <Typography
                  fontSize={
                    (theme.typography as ExtendedTypographyOptions).labelSmall
                      ?.fontSize
                  }
                  variant="caption"
                >
                  {user.email}
                </Typography>
              </>
            )}
          </Box>
        </SkeletonLoader>
        <SkeletonLoader
          isLoading={isLoading}
          skeletonCount={3}
          skeletonHeight={40}
        >
          <MainMenu isMobile={isMobile} handleDrawerClose={handleDrawerClose} />
        </SkeletonLoader>
      </Drawer>
      <Main open={open}>
        <SkeletonLoader isLoading={isLoading} skeletonCount={1}>
          <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </SkeletonLoader>
      </Main>
    </Box>
  );
};

export default Layout;
