import React, { useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  IconButton,
  Badge,
  Slide,
  Grid,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ArrowForwardIos as ArrowForwardIcon } from "@mui/icons-material";
import { Avatar, MessageModel } from "@chatscope/chat-ui-kit-react";
import { useTheme } from "@mui/material/styles";
import { formatMessageTime } from "@/utils/dateUtils";
import { useNotificationStore } from "@/hooks/useUserNotifications";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "@/components/SkeletonLoader";
import { SearchClients } from "@/domain/client.interface";
import dayjs from "dayjs";
import { useCreateAuditlog } from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import { ParticipantStatus } from "@/domain/participant.interface";

interface ContactListProps {
  contacts: SearchClients[];
  onSelectContact: (contactArn: string) => void;
  contactMessages: { [key: string]: MessageModel[] };
  selectedContactId: string | null;
  isMobile?: boolean;
  visible?: boolean;
  search: string;
  onSearchChange: (value: string) => void;
  isLoading: boolean;
}

const ContactList: React.FC<ContactListProps> = ({
  contacts,
  onSelectContact,
  contactMessages,
  selectedContactId,
  isMobile = false,
  visible = true,
  search,
  onSearchChange,
  isLoading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("clients");
  const { notifications, markNotificationAsRead } = useNotificationStore();
  const createAuditLog = useCreateAuditlog();
  const { userInfo } = useUserStore();

  useEffect(() => {
    notifications.forEach((notification) => {
      if (!notification.isRead && notification.sender === selectedContactId) {
        markNotificationAsRead(notification.id);
      }
    });
  }, [notifications, selectedContactId, markNotificationAsRead]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  const sortedContacts = useMemo(() => {
    return [...contacts].sort((a, b) => {
      const aMessages =
        contactMessages[a.participant.chimeInstanceUserArn] || [];
      const bMessages =
        contactMessages[b.participant.chimeInstanceUserArn] || [];

      const aHasMessages = aMessages.length > 0;
      const bHasMessages = bMessages.length > 0;

      if (aHasMessages && !bHasMessages) return -1;
      if (!aHasMessages && bHasMessages) return 1;

      if (aHasMessages && bHasMessages) {
        const aLastMessageTime = Number(
          aMessages[aMessages.length - 1].sentTime
        );
        const bLastMessageTime = Number(
          bMessages[bMessages.length - 1].sentTime
        );
        return bLastMessageTime - aLastMessageTime;
      }

      const aName =
        `${a.participant.firstName} ${a.participant.lastName}`.toLowerCase();
      const bName =
        `${b.participant.firstName} ${b.participant.lastName}`.toLowerCase();
      return aName.localeCompare(bName);
    });
  }, [contacts, contactMessages]);

  return (
    <Slide
      direction="left"
      in={visible || !isMobile}
      mountOnEnter
      unmountOnExit
      timeout={250}
    >
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 1,
            maxHeight: "calc(100vh - 122px)",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.surface.light2,
              px: 2,
              width: "100%",
              height: "48px",
              mb: 2,
            }}
            className="flex rounded-lg py-3 max-w-full items-center justify-between h-14"
          >
            <InputBase
              placeholder="Search ..."
              inputProps={{ "aria-label": "search" }}
              value={search}
              onChange={handleSearchChange}
            />
            <SearchIcon className="text-icon-primary" />
          </Box>

          <SkeletonLoader
            isLoading={isLoading && sortedContacts.length === 0}
            skeletonCount={5}
            skeletonHeight={72}
            skeletonType="rectangular"
          >
            {sortedContacts
              .filter(
                (c) =>
                  c.participant.status !==
                    ParticipantStatus.PENDING_VERIFICATION &&
                  c.participant.status !== ParticipantStatus.INACTIVE
              )
              .map((client) => {
                const contactId = client.participant.chimeInstanceUserArn;
                const lastMessage =
                  contactMessages[contactId]?.slice(-1)[0] || null;
                const lastMessageTime = lastMessage
                  ? dayjs(Number(lastMessage.sentTime)).isSame(dayjs(), "day")
                    ? formatMessageTime(Number(lastMessage.sentTime), "hh:mm A")
                    : formatMessageTime(
                        Number(lastMessage.sentTime),
                        isMobile ? "DD MMM" : "DD MMM YYYY, hh:mm A"
                      )
                  : "";

                const hasUnread = notifications.some(
                  (notification) =>
                    !notification.isRead && notification.sender === contactId
                );

                return (
                  <Box
                    key={contactId}
                    onClick={() => {
                      createAuditLog.mutate({
                        appType: "WEB_BACK_OFFICE",
                        module: MODULE_NAME.CHAT,
                        option: OPTIONS.CHAT_WINDOW,
                        actionCode: ACTION_CODE.CHAT_MESSAGES_VIEW,
                        action: "When entering after selecting a contact",
                        detail: `Viewed the chat messages of with ${client?.participant.firstName} ${client?.participant.lastName}`,
                        transactionDate: new Date(),
                        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
                        createdBy: userInfo?._id as string,
                        targetId: client?.participant._id as string,
                        programId: client?.lastEnrollment?.programId,
                        orgUnitId: client?.lastEnrollment.orgUnitId,
                      });
                      onSelectContact(client.participant.chimeInstanceUserArn);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                      cursor: "pointer",
                      backgroundColor:
                        contactId === selectedContactId
                          ? theme.palette.surface.light2
                          : "transparent",
                      "&:hover": {
                        backgroundColor: theme.palette.surface.light2,
                      },
                      borderRadius: contactId === selectedContactId ? 2 : 0,
                      borderBottom: `1px solid ${theme.palette.outline.main2}`,
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: theme.palette.surface.main,
                        color: theme.palette.textMain.dark2,
                        width: "40px",
                        height: "40px",
                        lineHeight: "43px",
                        textAlign: "center",
                      }}
                    >
                      {client.participant.firstName.charAt(0).toUpperCase()}
                      {client.participant.lastName.charAt(0).toUpperCase()}
                    </Avatar>

                    <Box sx={{ ml: 2, flexGrow: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{ mr: 1 }}
                          >
                            {client.participant.firstName}{" "}
                            {client.participant.lastName}
                            {hasUnread && (
                              <Badge
                                color="error"
                                variant="dot"
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                sx={{
                                  ml: 1,
                                  transform: "translateY(-30%)",
                                }}
                              />
                            )}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            alignSelf: "flex-start",
                            paddingTop: "4px",
                          }}
                          variant="caption"
                          color="textSecondary"
                        >
                          {lastMessageTime}
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {lastMessage?.message || t("chat.empty_message")}
                      </Typography>
                    </Box>

                    <IconButton sx={{ ml: 1 }}>
                      <ArrowForwardIcon
                        sx={{
                          fill: theme.palette.icon.dark,
                          width: ".5em",
                          height: ".5em",
                        }}
                      />
                    </IconButton>
                  </Box>
                );
              })}
          </SkeletonLoader>
        </Box>
      </Grid>
    </Slide>
  );
};

export default ContactList;
